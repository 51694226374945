<template>
  <div class="galaxy-frames" v-if="pageInfo[$route.params.frame]">
    <div class="content">
      <router-link
        class="button button_white mb-8 d-inline-flex px-8"
        :to="{
          name: 'Galaxy',
          query:
            user && Object.keys(user).length
              ? {}
              : { access: 'e03239b27e34a5f7f3bde739459dd537' },
        }"
      >
        <span>Назад</span>
      </router-link>
      <div
        class="galaxy-frames__title"
        v-html="pageInfo[$route.params.frame].title"
      ></div>
      <div
        class="galaxy-frames__description"
        v-html="pageInfo[$route.params.frame].description"
      ></div>
      <div class="galaxy-frames__container">
        <iframe
          class="maths"
          v-if="$route.params.frame === 'maths'"
          frameborder="0"
          src="https://www.educaplay.com/game/14920116-galaxy.html"
        ></iframe>
        <iframe
          class="galaxy_sync"
          v-if="$route.params.frame === 'galaxy_sync'"
          frameborder="0"
          src="https://puzzel.org/en/matching-pairs/embed?p=-NUlUdGDDzm5hf5B9Loi"
        ></iframe>
        <div v-if="$route.params.frame === 'digital_quiz'" class="digital_quiz">
          <div
            data-tf-widget="HeKZuuTC"
            data-tf-opacity="100"
            data-tf-hide-headers=""
            data-tf-inline-on-mobile=""
            data-tf-iframe-props="title=Квиз"
            data-tf-medium="snippet"
            style="width: 100%; height: 100%"
          ></div>
        </div>
        <iframe
          class="puzzle"
          v-if="$route.params.frame === 'puzzle'"
          src="https://puzzel.org/en/jigsaw/embed?p=-NUlVvjR7a-x0cabw8Oi"
          frameborder="0"
        ></iframe>
        <iframe
          class="sharing"
          v-if="$route.params.frame === 'share'"
          src="https://padlet.com/embed/6wayk5wy00iz3dzg"
          frameborder="0"
          allow="camera;microphone;geolocation"
        ></iframe>
        <div
          class="find_word"
          v-if="$route.params.frame === 'find_word'"
          id="otp_wgt_7uvijfx3j7kcg"
        ></div>
        <div class="callback" v-if="$route.params.frame === 'callback'">
         
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "GalaxyFrame",
  metaInfo() {
    if (this.pageInfo[this.$route.params.frame]) {
      return { title: this.pageInfo[this.$route.params.frame].title };
    }
  },
  data: () => ({
    pageInfo: {
      maths: {
        title: "GALAXY Арифметика",
        description:
          "Простая арифметика: составьте пары уравнения, чтобы увидеть результат!",
      },
      share: {
        title: "Делитесь ссылками",
        description:
          "Мы создали общее поле для обмена полезной информации с коллегами. Делитесь ссылками на актуальные ресурсы!",
      },
      find_word: {
        title: "Найдите слова",
        description:
          "Небольшая простая игра для того, чтобы отвлечься от трансляции в перерывах. Найдите простые, но важные слова, которые мы зашифровали для Вас!",
      },
      galaxy_sync: {
        title: "Галактическая синхронизация",
        description:
          "Внимательно слушаете выступление наших спикеров? Тогда соотнесите тезис и спикера!",
      },
      puzzle: {
        title: "Соберите пазл",
        description: "Соберите зашифрованное изображение по частям!",
      },
      digital_quiz: {
        title: "Digital Квиз",
        description: "Небольшая викторина по темам выступления наших спикеров.",
      },
      callback: {
        title: "Обратная связь",
        description:
          "Ваше мнение очень важно для нас и помогает нам становиться лучше. Пожалуйста, пройдите анкету обратной связи.",
      },
    },
  }),
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    findWord(w, d, n, s, rp) {
      let otp_wjs_dt = new Date().getTime();
      w[n] = w[n] || [];
      rp = {};
      w[n].push(function () {
        otp_render_widget(
          d.getElementById("otp_wgt_7uvijfx3j7kcg"),
          "onlinetestpad.com",
          "7uvijfx3j7kcg",
          rp
        );
      });
      s = d.createElement("script");
      s.type = "text/javascript";
      s.src = "//onlinetestpad.com/js/widget.js?" + otp_wjs_dt;
      s.async = true;
      d.getElementsByTagName("head")[0].appendChild(s);
    },
    digitalQuiz() {
      let s = document.createElement("script");
      s.type = "text/javascript";
      s.src = "//embed.typeform.com/next/embed.js";
      s.async = true;
      document.getElementsByTagName("head")[0].appendChild(s);
    },
    callbackIframe(t, e, s, n) {
      var o, a, c;
      (t.SMCX = t.SMCX || []),
        e.getElementById(n) ||
          ((o = e.getElementsByTagName(s)),
          (a = o[o.length - 1]),
          (c = e.createElement(s)),
          (c.type = "text/javascript"),
          (c.async = !0),
          (c.id = n),
          (c.src =
            "https://widget.surveymonkey.com/collect/website/js/tRaiETqnLgj758hTBazgd_2BJgAqj0xqJFcLzqA_2BmcWflBhlFbu8D8wOzeEOXgkZre.js")
          // a.parentNode.insertBefore(c, a));
          );
          document.querySelector(".callback").appendChild(c)
    },
  },
  mounted() {
    if (
      !this.pageInfo[this.$route.params.frame] ||
      this.pageInfo[this.$route.params.frame].closed
    ) {
      this.$router.push({ name: "Galaxy" });
    }
    document.querySelector(
      "#app"
    ).style.backgroundImage = `url(${require("@/assets/img/galaxy/frames_back.jpg")})`;
    if (document.querySelector("#otp_wgt_7uvijfx3j7kcg")) {
      this.findWord(window, window.document, "otp_widget_callbacks");
    }
    if (document.querySelector(".digital_quiz")) {
      this.digitalQuiz();
    }
    if (document.querySelector(".callback")) {
      this.callbackIframe(window, document, "script", "smcx-sdk");
    }
  },
  beforeDestroy() {
    document.querySelector("#app").style.backgroundImage = "";
  },
};
</script>

<style lang="scss" scoped>
.galaxy-frames {
  padding: 64px 0 0;

  @media screen and (max-width: 1220px) {
  }

  @media screen and (max-width: 767px) {
    padding-top: 40px;
  }

  &__title {
    font-family: "Roboto Slab", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 42px;
    line-height: 48px;
    color: #ffffff;

    @media screen and (max-width: 1220px) {
    }

    @media screen and (max-width: 767px) {
      font-size: 24px;
      line-height: 32px;
    }
  }

  &__description {
    margin: 64px 0;
    font-family: "Roboto Slab", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 23px;
    line-height: 29px;
    color: #ffffff;

    @media screen and (max-width: 1220px) {
      margin: 40px 0;
    }

    @media screen and (max-width: 767px) {
      margin: 24px 0 40px;
      font-size: 16px;
      line-height: 22px;
    }
  }

  &__container {
    display: flex;
  }

  iframe {
    margin: 0 auto;

    @media screen and (max-width: 1220px) {
      width: 100%;
      // height: 500px;
    }

    @media screen and (max-width: 767px) {
      width: 100%;
      // height: 300px;
    }
  }
}

.maths {
  margin: 0 auto;

  width: 795px;
  height: 690px;
  @media screen and (max-width: 1220px) {
    width: 100%;
    height: 590px;
  }

  @media screen and (max-width: 767px) {
    width: 100%;
    height: 500px;
  }
}

.sharing {
  margin: 0 auto;

  width: 1000px;
  height: 600px;
  @media screen and (max-width: 1220px) {
    width: 100%;
    height: 500px;
  }

  @media screen and (max-width: 767px) {
    width: 100%;
    height: 450px;
  }
}

.find_word {
  margin: 0 auto;

  width: 900px;
  height: 675px;
  @media screen and (max-width: 1220px) {
    width: 100%;
    height: 675px;
  }

  @media screen and (max-width: 767px) {
    width: 100%;
    height: 825px;
  }
}

.puzzle {
  margin: 0 auto;

  width: 900px;
  height: 550px;
  @media screen and (max-width: 1220px) {
    width: 100%;
    height: 550px;
  }

  @media screen and (max-width: 767px) {
    width: 100%;
    height: 500px;
  }
}

.galaxy_sync {
  margin: 0 auto;

  width: 800px;
  height: 800px;
  @media screen and (max-width: 1220px) {
    width: 100%;
    height: 700px;
  }

  @media screen and (max-width: 767px) {
    width: 100%;
    height: 700px;
  }
}

.digital_quiz {
  margin: 0 auto;

  width: 1000px;
  height: 600px;
  @media screen and (max-width: 1220px) {
    width: 100%;
    height: 550px;
  }

  @media screen and (max-width: 767px) {
    width: 100%;
    height: 500px;
  }
}

.callback {
  margin: 0 auto;

  width: 750px;
  height: auto;
  @media screen and (max-width: 1220px) {
    width: 100%;
    height: auto;
  }

  @media screen and (max-width: 767px) {
    width: 100%;
    height: auto;
  }
}
</style>